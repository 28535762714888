<template>
  <van-popup
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
    :style="{ width: '100%', maxHeight: '80%', background: '#1E232D', padding: '0 10px' }"  
    :transition-appear=true
    position="bottom"
    duration="0.5"
    @input="closePop"
    @close='closePop'
    round
  >
    <div class="socail-wrap">
      <div class="title">{{ $t('common.tip') }}</div>
      <div class="content">
        <div class="item">
          {{ $t('common.personTip')}}
        </div>
      </div>

    </div>

  </van-popup>

</template>

<script>
import $gbUtils from "@/utils/gbUtils";
import {mapActions} from "vuex";
export default {
  name: 'PersonTipPop',
  components: { },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    }
  },
  methods: {
    ...mapActions([
      "changeShowPersonTipPop"
    ]),
    closePop () {
      console.log('close PersonTipPop')
      this.changeShowPersonTipPop(false)
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-wrap {
  padding: 0 !important;
  border-radius: 6px;
}

.socail-wrap {
  color: #fff;
  padding: 20px 10px;
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
    .item {
      width: 100%;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      border-radius: 4px;
      line-height: 1.3;
      font-size: 14px;
    }
    :last-child {
      margin-bottom: 0;
    }
    
  }
  .center{
    display: flex;
    justify-content: center;
  }
}
.van-popup--bottom.van-popup--round {
  border-radius: 10px 10px 0 0;
}
</style>