<template>
  <div :class="subTitle ? 'header_wrap has-sub-title' : 'header_wrap'">
    <!-- Header -->
    <div id="header" class="header" :style="{ backgroundColor: bgColor }">
      <div class="left">
        <img v-if="hideBack" class="mask" src="@/assets/img/logo.png" alt="">
        <div v-else class="title" @click="$gbUtils.handleBack">
          <img src="@/assets/img/goback.svg" />
          <div>{{ title }}</div>
        </div>
      </div>
      <div v-if="!isSign" class="right" @click="handleAccountLogin">{{ $t('home.headConnect') }}</div>
      <div v-else class="user-icon" @click="handleShowUserMenu">
        <template v-if="userInfo.headPhoto">
          <img :src="$gbUtils.formatImgUrl(userInfo.headPhoto)" />
        </template>
        <template v-else>
          <img src="@/assets/img/user-default.png" />
        </template>
        <span class="status"></span>
      </div>
    </div>
    <!-- 子标题 -->
    <div class="sub-title" v-if="subTitle" :style="{ backgroundColor: subTitleBgColor }">
      <div :style="{ color: subTitleColor }">{{ subTitle }}</div>
      <div class="bg-btn" v-if="showBgUpload">
        <img src="@/assets/img/icon/camera.svg" />
        <div>{{ $t('userDetail.changeBgImg') }}</div>
      </div>
    </div>
    <slot />
   
    <!--账号登陆-->
    <van-popup v-model="showLoginPop" class="wallet-window" position="bottom"
      :overlayStyle="{ background: 'rgba(123,101,128,0.7)' }" :style="{ width: '100%' }"
      round
      @click-overlay="closeAccountLoginPop">
      <div class="login-wrap">
        <div class="top-white-line"></div>
        <div class="title">{{ $t('login.accountLogin') }}</div>
        <div class="tip">开始使用面具</div>
        <div class="content">
          <div class="input-wrap">
            <img src="@/assets/img/user/login-user.svg" />
            <input v-model="account" type="text" placeholder="请输入登陆账号" />
          </div>
          <div class="input-wrap">
            <img src="@/assets/img/user/login-pwd.svg" />
            <input v-model="password" type="password" placeholder="请输入登陆密码" />
          </div>
          <div class="dot-line"></div>
          
          <div class="btn-wrap">
            <van-button :loading="loading" class="btn" type="primary" @click="handleWeb2Login" >{{ $t('login.login') }}</van-button>
          </div>

          <div class="regist-link" >
            <span @click="handleShowRegisterPop">{{ $t('login.goRegister') }}</span>
          </div>
          <div class="botton-white-line"></div>
        </div>
      </div>
    </van-popup>
          
    <!--账号注册-->
    <van-popup v-model="showRegisterPop" class="wallet-window" position="bottom"
      :overlayStyle="{ background: 'rgba(123,101,128,0.7)' }" :style="{ width: '100%' }"
      round
      @click-overlay="closeAccountLoginPop">
      <div class="login-wrap">
          <div class="top-white-line"></div>
          <div class="reg-title">
            <div class="word">{{ $t('login.register') }}</div>
            <div class="goback" @click="goLoginPop">返回登录</div>
          </div>
          <div class="reg-content">
            <div class="input-wrap">
              <img src="@/assets/img/user/login-user.svg" />
              <input v-model="registerAccount" type="text" placeholder="请输入登陆账号" />
            </div>
            <div class="input-wrap">
              <img src="@/assets/img/user/login-pwd.svg" />
              <input v-model="registerPassword" type="password" placeholder="请输入登陆密码" />
            </div>
            <div class="input-wrap">
              <img src="@/assets/img/user/login-pwd.svg" />
              <input v-model="confirmPassword" type="password" placeholder="请输入登陆密码" />
            </div>
            <div class="input-wrap">
              <img src="@/assets/img/user/invite-code-4x.png" />
              <input v-model="inviteCode"  type="text" :placeholder="$t('login.inviteCodePlaceHolder')" :readonly="inviteCodeReadOnly" />
            </div>

            <div class="dot-line"></div>

            <div class="reg-btn-wrap">
              <van-button :loading="loading" class="btn" type="primary" @click="handleRegister">{{ $t('login.register') }}</van-button>
            </div>
            <div class="botton-white-line "></div>
          </div>
      </div>
    </van-popup>

    <!--用户菜单-->
    <PersonTipPop v-model="showPersonTipPop" />
  </div>
</template>

<script>
import bus from '../utils/bus'
import { mapState, mapActions } from 'vuex'
import { web2Login, web2Register } from '@/service/login'
import $gbUtils from "@/utils/gbUtils";
import { checkPlayerInfo } from "@/service/base";
import PersonTipPop from './PersonTipPop'

export default {
  name: "headerBar",
  components: {
    PersonTipPop
  },
  data() {
    return {
      bottom: "bottom",
      loginShow: false,
      connectShow: true,
      needSign: true,
      userMenuShow: false,
      showRegisterPop: false,
      account: '',
      password: '',
      registerAccount: '',
      registerPassword: '',
      confirmPassword: '',
      inviteCode: '',
      loading: false,
      inviteCodeModel: false, // 邀请链接注册模式
      inviteCodeReadOnly: false,
    };
  },
  props: {
    hideBack: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Mask'
    },
    subTitle: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: ''
    },
    subTitleBgColor: {
      type: String,
      default: ''
    },
    subTitleColor: {
      type: String,
      default: '#a1a1a5'
    },
    showBgUpload: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    },
    ...mapState([
      "lang",
      "userInfo",
      "showLoginPop",
        "showPersonTipPop"
    ]),
    isSign() {
      return this.$gbUtils.isSign();
    },
  },
  watch: {},
  methods: {
    ...mapActions([
      "changeToken",
      "changeIsLogin",
      "changeUserInfo",
      "changeShowLoginPop",
        "changeShowPersonTipPop"
    ]),
    changeSide() {
      bus.$emit("showSideBar");
    },
    closeAccountLoginPop() {
      this.changeShowLoginPop(false)
      this.cleanLoginAndRegisterData()
    },
    // 清空输入框数据
    cleanLoginAndRegisterData() {
      this.account = ''
      this.password = ''
      this.registerAccount = ''
      this.registerPassword = ''
      this.confirmPassword = ''
      if (!this.inviteCodeModel) {
        this.inviteCode = ''
      }
    },
    // 注册弹窗
    handleShowRegisterPop() {
      this.closeAccountLoginPop()
      this.showRegisterPop = true
      this.cleanLoginAndRegisterData()
    },
    // 返回登陆弹窗
    goLoginPop() {
      this.showRegisterPop = false
      this.changeShowLoginPop(true)
      this.cleanLoginAndRegisterData()
    },
    // 普通账号登陆
    async handleAccountLogin() {
      this.changeShowLoginPop(true)
    },
    // 账号注册
    async handleRegister() {
      if (!this.registerAccount) {
        this.$toast(this.$t('login.accountEmpty'))
        return
      }
      if (this.registerAccount.length < 6 || this.registerAccount.length > 20) {
        this.$toast(this.$t('login.accountLength'))
        return
      }
      if (!this.registerPassword) {
        this.$toast(this.$t('login.passwordEmpty'))
        return
      }
      if (this.registerPassword.length < 6 || this.registerPassword.length > 20) {
        this.$toast(this.$t('login.passwordLength'))
        return
      }
      if (!this.confirmPassword) {
        this.$toast(this.$t('login.confirmPasswordEmpty'))
        return
      }
      if (this.registerPassword !== this.confirmPassword) {
        this.$toast(this.$t('login.passwordNotSame'))
        return
      }

      const params = {
        username: this.registerAccount,
        password: this.registerPassword,
        inviteCode: this.inviteCode,
      }
      this.loading = true
      const resp = await web2Register(params)
      this.loading = false
      if (!resp.success) {
        let tipMsg = resp.msg
        if (tipMsg === 'username is exist' && this.lang === 'ZH') {
          tipMsg = "用户名已存在"
        }
        if (tipMsg === 'inviteCode is error' && this.lang === 'ZH') {
          tipMsg = "邀请码错误"
        }
        this.$toast(tipMsg)
        return
      }
      this.$toast(this.$t('login.registerSuccess'))
      this.goLoginPop()
      
    },

    // web2登陆
    async handleWeb2Login() {
      if (!this.account) {
        this.$toast(this.$t('login.accountEmpty'))
        return
      }
      if (!this.password) {
        this.$toast(this.$t('login.passwordEmpty'))
        return
      }
      const params = {
        username: this.account,
        password: this.password,
      }
      this.loading = true
      const resp = await web2Login(params)
      this.loading = false
      if (!resp.success) {
        let errorMsg = ''
        if (resp.msg === 'username or password error') {
          errorMsg = this.$t('login.usernameOrPasswordError')
        }
        this.$toast(errorMsg || resp.msg)
        await this.loginFail()
        return
      }
      this.$toast(this.$t('login.loginSuccess'))
      await this.loginSuccess(resp)
    },
    // 登陆成功
    async loginSuccess(resp) {
      this.closeAccountLoginPop()
      this.changeIsLogin(1)
      this.changeToken(resp.data.token)

      const respUserInfo = resp.data.player
      let userInfo = {...respUserInfo}
      userInfo.userId = respUserInfo._id
      
      this.changeUserInfo(userInfo)
      this.connectShow = false
      if (this.userInfo.sex === 2) {
        // 女性用户,检查是否填写资料
        const resp = await checkPlayerInfo()
        if (!resp.success) {
          this.changeShowPersonTipPop(true)
        }
      }
      if (this.userInfo.sex === 0) {
        // 跳转到性别选择页面
        this.$gbUtils.handleToPage('/gender')
      } else {
        bus.$emit("loginSuccessReload");
      }
    },
    // 登陆失败
    async loginFail() {
      this.changeToken('')
      this.changeIsLogin(1)
      this.changeUserInfo({})
      this.connectShow = true
    },
    handleShowUserMenu() {
      console.log('open')
      bus.$emit("showUserSideBar");
    },
    init() {
      if (!this.showLoginPop && !this.isSign) {
        this.changeShowLoginPop(true)
      }
    },
  },
  created() {
    bus.$on("openRegisterPop", (inviteCode) => {
      if (inviteCode) {
        this.handleShowRegisterPop()
        this.inviteCode = inviteCode
        this.inviteCodeReadOnly = true
        this.inviteCodeModel = true
      }
    });
  },
  mounted() {

  },
};
</script>


<style lang="scss" scoped>
// header组件样式 begin
.header_wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;

  &.has-sub-title {
    padding-top: 50px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #14151e;
  z-index: 2;

  .message-entry {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid #d9d9d9;
    right: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    img {
      width: 86%;
    }

    span {
      background: red;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 100%;
    }
  }

  .is-invisible {
    position: absolute;
    pointer-events: none;
    filter: progid:dximagetransform.microsoft.alpha(Opacity=0);
    opacity: 0;
    top: 0;
    left: 0;
  }

  .left {
    display: flex;
    -webkit-box-align: center;
    align-items: center;

    .mask {
      width: 78px;
    }

    .title {
      display: flex;

      div {
        margin-left: 20px;
        font-size: 30px;
        font-family: Mulish;
        color: #fff;
        font-weight: 800;
      }
    }
  }

  .right {
    display: flex;
    width: 80px;
    height: 34px;
    color: #ffffff;
    background: #524774;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    text-align: justify;
    border-radius: 8px;
  }

  .user-icon {
    width: 30px;
    height: 30px;
    position: relative;

    .status {
      width: 8px;
      height: 8px;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #0cbb08;
      border-radius: 100%;
      z-index: 1;

      &.offLine {
        background: red;
      }
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}

.sub-title {
  font-size: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 10px 20px;


  div {
    height: 32px;
    line-height: 32px;
    color: #a1a1a5;
    font-size: 20px;
    font-weight: 600;
  }

  .bg-btn {
    display: flex;
    background: rgba(82, 71, 116, 0.3);
    height: 32px;
    line-height: 32px;
    align-items: center;
    padding: 0 10px;
    border-radius: 15px;

    div {
      font-size: 14px;
      margin-left: 5px;
    }
  }

}

// header组件 end

// 链接钱包 begin
.wallet-window {
  .wallet_wrap {
    width: 100%;
    height: 100%;
    background: #1E232D;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 10px;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      margin-top: 10px;
    }

    .content {
      width: 100%;
      height: calc(100% - 45px);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-radius: 14px 14px 0px 0px;
      padding-bottom: 34px;

      .tips {
        color: #fff;
        font-size: 11px;
        margin: 15px auto;
      }

      .icon {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 35px;

        .left {
          width: 130px;
          height: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          background: rgba(82, 71, 116, 0.4);
          border-radius: 10px;

          .logo {
            margin-top: 10px;
          }

          img {
            width: 68px;
            height: 68px;
          }

          .word {
            font-size: 12px;
            color: #fff;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 16px;
          }
        }

        .right {
          width: 130px;
          height: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          background: rgba(82, 71, 116, 0.4);
          border-radius: 10px;

          .logo {
            margin-top: 10px;
          }

          img {
            width: 68px;
            height: 68px;
          }

          .word {
            font-size: 12px;
            color: #fff;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .login-wrap {
    width: 100%;
    height: 100%;
    background: #1E232D;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    box-sizing: border-box;
    position: relative;
    flex-wrap: wrap;
    background-image: url('../assets/img/user/login-mask-bg.png');
    background-position: 90% 0%;
    background-size: 280px 230px;
    background-repeat: no-repeat;
   
    .title,.reg-title {
      width: 100%;
      height: 28px;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      margin-top: 50px;
      position: relative;
      line-height: 28px;
      padding-left: 10px;
    }

    .reg-title {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;

      .goback {
        color: #FD2084;
        font-size: 16px;
      }
    }

    .tip {
      margin-top: 10px;
      padding-left: 10px;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      height: 20px;
    }

    .content,.reg-content {
      width: 100%;
      margin-top: 30px;

      .input-wrap {
        width: 100%;
        display: flex;
        padding: 0 10px;
        margin-bottom: 20px;
        position: relative;
        img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 20px;
          filter: brightness(200%);
        }

        input {
          width: 100%;
          min-height: 40px;
          background: #14151E;
          padding-left: 46px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .dot-line {
        width: calc(100% - 20px);
        height: 0px;
        margin-bottom: 10px;
        border: 1px dashed #3D3F51;
        margin-top: 30px;
        margin-left: 10px;
      }

      .regist-link {
        text-decoration-line: underline;
        color: #FD2084;
        font-size: 14px;
        margin-bottom: 64px;
        text-align: center;
      }

      .btn-wrap,.reg-btn-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;
        padding-bottom: 30px;

        .btn {
          background-color: #524774;
          border-color: #524774;
          height: 38px;
          line-height: 38px;
          width: 200px;
          border-radius: 4px;

        }
      }
      .reg-btn-wrap {
        margin-bottom: 64px;
      }
    }
    .reg-content {
      margin-top: 20px;
    }
  }
}
/deep/.van-popup--bottom.van-popup--round {
    border-radius: 10px 10px 0 0;
}

/deep/.van-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(20, 21, 30, 0.6) !important;
  -webkit-backdrop-filter: blur(0.8px);
  backdrop-filter: blur(0.8px);
}

/deep/.van-popup {
  background: #1E232D;
}

/deep/.van-button__text {
  font-size: 14px;
  font-weight: bold;
}
// 链接钱包 end
</style>
