import request from '../utils/request'

/**
 * 获取标签
 * @param {*} labelType : 行业:sc_label_job 气质类型:sc_label__temperament
 * @returns 
 */
export const getLables = (labelType) => {
  return request('GET', '/playerBase/label?dictType='+labelType, null, true, false)
}

/**
 * 获取首页公告
 */
export const getHomeNotice = () => {
  const params = {
    type: "20"
  }
  return request('POST', '/playerLogin/notice', params, false, false)
}

/**
 * 获取公告列表
 * @param params
 */
export const getNoticeList = (params) => {
  return request('POST', '/playerLogin/notice', params, false, false)
}

/**
 * 查询审核失败原因
 * @param params
 * @returns {Promise<unknown>}
 */
export const queryVerifyFailReason = (params) => {
  return request('POST', '/playerBase/detailAudit', null, true, false)
}

/**
 * 检查是否有填写资料
 */
export const checkPlayerInfo = () => {
  return request('POST', '/playerBase/checkPlayer', null, true, false)
}